<template>
  <div class="content">
    <siteHeader />
    <div class="container-fluid mt--9">
      <div>
        <card class="no-border-card" body-classes="pb-1" footer-classes="pb-2">
          <template v-slot:header>
            <h3>User</h3>
          </template>
          <div v-if="isLoading">
            <base-alert type="danger" dismissible>
              <span class="alert-inner--text">
                Record Deleted Successfully</span
              >
              <button
                type="button"
                class="close"
                data-dismiss="alert"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </base-alert>
          </div>
          <div>
            <div
              class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <base-button
                type="primary"
                class="mb-4"
                size="sm"
                @click="$router.push('create_user/undefined')"
                >Create</base-button
              >
              <div>
                <el-input
                  type="search"
                  class="mb-3"
                  clearable
                  prefix-icon="el-icon-search"
                  style="width: 200px"
                  placeholder="Search records"
                  v-model="searchQuery"
                  aria-controls="datatables"
                >
                </el-input>
              </div>
            </div>
            <el-table
              :data="queriedData"
              row-key="id"
              header-row-class-name="thead-light"
              @row-dblclick="dbSelected"
            >
              <el-table-column
                v-for="column in tableColumns"
                :key="column.label"
                v-bind="column"
                sortable
              >
              </el-table-column>
              <el-table-column
                min-width="100px"
                align="right"
                label="Active"
                sortable
              >
                <template v-slot:default="props">
                  <div class="d-flex">
                    <div v-if="props.row.is_active == '1'">Active</div>
                    <div v-if="props.row.is_active == '2'">Inactive</div>
                  </div>
                </template>
              </el-table-column>

              <el-table-column
                min-width="100px"
                align="right"
                label="Actions"
                sortable
              >
                <template v-slot:default="props">
                  <div class="d-flex">
                    <i
                      class="fas fa-trash-alt"
                      @click="ondelete(props.row)"
                    ></i>
                    &nbsp;
                    <i class="fas fa-edit" @click="onEdit(props.row)"></i>
                    &nbsp;
                    <i class="far fa-eye" @click="openModel(props.row)"></i>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <template v-slot:footer>
            <div
              class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <div class="">
                <el-select
                  class="select-primary pagination-select"
                  v-model="pagination.perPage"
                  placeholder="Per page"
                >
                  <el-option
                    class="select-primary"
                    v-for="item in pagination.perPageOptions"
                    :key="item"
                    :label="item"
                    :value="item"
                  >
                  </el-option>
                </el-select>
                <p class="card-category">
                  Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
                </p>
              </div>
              <base-pagination
                class="pagination-no-border"
                v-model="pagination.currentPage"
                :per-page="pagination.perPage"
                :total="total"
              >
              </base-pagination>
              <modal v-model:show="modals.modal3">
                <template v-slot:header>
                  <h6 class="modal-title" id="modal-title-default">
                    User Details
                  </h6>
                </template>

                <div class="row">
                  <div class="col-6">Username:</div>
                  <div class="col-6">
                    {{ this.mode_active.username }}
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-6">Email:</div>
                  <div class="col-md-6">
                    {{ this.mode_active.email }}
                  </div>
                </div>
                <template v-slot:footer>
                  <base-button
                    type="link"
                    class="ml-auto"
                    @click="modals.modal3 = false"
                    >Close
                  </base-button>
                </template>
              </modal>
            </div>
          </template>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import { RepositoryFactory } from "../../repositories/RepositoryFactory";
const usersRepository = RepositoryFactory.get("users");
import {
  ElTable,
  ElTableColumn,
  ElSelect,
  ElOption,
  ElInput,
} from "element-plus";
import BasePagination from "@/components/BasePagination";
export default {
  components: {
    BasePagination,
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
    [ElTable.name]: ElTable,
    [ElInput.name]: ElInput,
    [ElTableColumn.name]: ElTableColumn,
  },
  name: "projects-table",
  computed: {
    pagedData() {
      return this.userdata.slice(this.from, this.to);
    },
    /***
     * Searches through table data and returns a paginated array.
     * Note that this should not be used for table with a lot of data as it might be slow!
     * Do the search and the pagination on the server and display the data retrieved from server instead.
     * @returns {computed.pagedData}
     */
    queriedData() {
      if (!this.searchQuery) {
        return this.pagedData;
      }
      let result = this.userdata.filter((row) => {
        let isIncluded = false;
        for (let key of this.propsToSearch) {
          let rowValue = row[key].toString();
          if (
            rowValue.includes &&
            rowValue.toLowerCase().includes(this.searchQuery)
          ) {
            isIncluded = true;
          }
        }
        return isIncluded;
      });

      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.userdata.length;
    },
  },
  data() {
    return {
      modals: {
        modal3: false,
      },
      mode_active: {},
      searchQuery: "",
      isLoading: false,
      tableData: [],
      userdata: [],
      pagination: {
        perPage: 25,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      propsToSearch: ["username", "email"],
      tableColumns: [
        {
          prop: "username",
          label: "Username",
          minWidth: 100,
        },
        {
          prop: "email",
          label: "Email",
          minWidth: 100,
        },
      ],

      fuseSearch: null,
      searchedData: [],
    };
  },
  methods: {
    openModel(row) {
      this.mode_active = row;

      this.modals.modal3 = true;
    },
    async ondelete(row) {
      this.model = row;
      this.model.is_delete = "Yes";
      let data = RepositoryFactory.JSON_to_URLEncoded(this.model, "data", []);
      let response = await usersRepository.delete(data);
      this.getList();
      if (response.data.status == 101) {
        this.isLoading = true;
        setTimeout(() => {
          this.isLoading = false;
        }, 1000);
      }
    },
    dbSelected(row) {
      this.$router.push({
        name: "createuser",
        params: { data: row.id },
      });
    },
    onEdit(row) {
      this.$router.push({
        name: "createuser",
        params: { data: row.id },
      });
    },

    getList() {
      usersRepository.getUser().then((data) => {
        this.tableData = Object.freeze(data);
        this.userdata = this.tableData.data;
      });
    },
  },
  mounted() {
    this.getList();
  },
};
</script>
<style></style>
